<template>
    <div v-if="!checkIsLogin()" class="container-fluid">
        <div class="haeder-nav flex-d">
            <div class="header-logo" v-if="siteSettings()">
                <router-link to="/">
                    <img  rel="preload"  :src="siteSettings()?.aws_url + siteSettings()?.storage_path.domain_image + siteSettings()?.logo" alt="">
                </router-link>
            </div>
            <div class="user-login index-view">
                <div class="mobile-login flex-d">
                    <button v-if="siteSettings()?.business_type == 2" type="button" class="btn btn-login-1 btn-register" data-bs-toggle="modal"
                        data-bs-target="#register-btn">
                        {{ translatedLangData('register','Register')}}
                    </button>
                    <button v-else class="btn btn-login-1 btn-register" @click="$emit('demo-login')">
                        {{ translatedLangData('demo', 'Demo') }}
                    </button>
                    <button type="button" class="btn btn-login-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop-one">
                        <i class="fa-solid fa-user"></i>{{ translatedLangData('login','Login')}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div v-else-if="checkIsLogin()" class="container-fluid">
        <div class="haeder-nav flex-d">
            <div class="top-side-search">
                <div class="header-logo">
                    <router-link to="/">
                        <img  rel="preload"  :src="siteSettings()?.aws_url + siteSettings()?.storage_path.domain_image + siteSettings()?.logo" alt="">
                    </router-link>
                </div>
            </div>
            <div class="user-login">
                <div class="main-exposure ng-star-inserted">
                    <div class="be_div">
                        <p v-if="siteSettings()?.business_type == 1">
                            <router-link to="/market-analysis">
                                {{ translatedLangData('bal', 'Bal') }}:<b v-if="userDataFunc">{{ parseFloat(userDataFunc.balance).toFixed(2) }}</b>
                            </router-link>
                            <router-link to="/market-analysis">
                                {{ translatedLangData('exp', 'Exp') }}:<b v-if="userDataFunc">{{ parseFloat(userDataFunc.expl ?? 0).toFixed(2) }}</b>
                            </router-link>
                        </p>
                        <p v-else>
                            <router-link to="/market-analysis">
                                {{ translatedLangData('bal', 'Bal') }}:<b v-if="userDataFunc">{{ parseFloat(userDataFunc.balance).toFixed(2) }}</b>
                            </router-link>
                            <router-link to="/market-analysis">
                                {{ translatedLangData('exp', 'Exp') }}:<b v-if="userDataFunc">{{ parseFloat(userDataFunc.expl ?? 0).toFixed(2) }}</b>
                            </router-link>
                        </p>
                    </div>
                    <div class="dropdown drop-side">
                        <button class="btn btn-secondary dropdown-toggle drop-menu" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                            <i class="fa-solid fa-user"></i>{{ translatedLangData('my-account','My Account')}}
                        </button>
                    </div>
                </div>
            </div>
            
            <div class="balance">
                <!-- <div class="header-deposit-wallet-sec"  v-if="siteSettings()?.business_type == 2">
                    <a @click="getWalletGetway()" href="Javascript:void(0);" class="header-deposit-wallet">
                        <img  rel="preload"   src="@/assets/images/wallet-deposit-icon.webp" alt="">
                    </a>
                </div> -->
                <div class="mo_user-blnc">
                    <b v-if="userData" class="head-username"><i class="fa-solid fa-user"></i>{{ userData.userid }}</b>
                    <ul v-if="siteSettings()?.business_type == 1">
                        <li>
                            <router-link to="/market-analysis">
                                {{ translatedLangData('bal', 'Bal') }}:<b v-if="userDataFunc"> ({{ parseFloat(userDataFunc.balance).toFixed(2) }})</b>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/market-analysis" class="exp-topcount">{{ translatedLangData('exp', 'Exp') }}
                                <span v-if="userDataFunc" class="exp">({{ Math.abs(userDataFunc.expl ?? 0).toFixed(2) }})</span>
                            </router-link>
                        </li>
                    </ul>
                    <ul v-else>
                        <li>
                            <router-link to="/market-analysis">
                                {{ translatedLangData('bal', 'Bal') }}:<b v-if="userDataFunc"> ({{ parseFloat(userDataFunc.balance).toFixed(2) }})</b>    
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/market-analysis" class="exp-topcount">{{ translatedLangData('exp', 'Exp') }}:
                                <span v-if="userDataFunc" class="exp">({{ Math.abs(userDataFunc.expl ?? 0).toFixed(2) }})</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="header-deposit-withdraw-btn-sec" v-if="siteSettings()?.business_type == '2'">
            <a @click="getWalletGetway()" class="btn_deposit"> 
                <img  rel="preload"  src="@/assets/images/deposit-icon.png" class="img-fluid">{{ translatedLangData('deposit', 'Deposit') }}
            </a>
            <a @click="getWalletGetway()" class="btn_withdrawal">
                <img  rel="preload"  src="@/assets/images/withdrawal-icon.png" class="img-fluid">{{ translatedLangData('withdrawal', 'Withdrawal') }}
            </a>
        </div>
    </div>
</template>
<script>
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../../services/api';
import * as apiName from '../../services/urls';
import { getWalletGetwayServiceCall } from "@/modules/wallet/services/wallet-service";

export default {
    name: 'Header',
    inject:['translatedLangData'],
    data() {
        return {
            userData: null,
        }
    },
    mounted() {
        this.userData = this.$store?.getters?.stateUser;
    },
    computed:{
        userDataFunc() {
            return this.$store?.getters?.stateUser;
        }
    },
    methods: {

        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
        userDataFunc() {
            return this.$store?.getters?.stateUser;
        },
        logoutBtnClick() {
            this.logoutServiceCall();
        },
        logoutServiceCall() {
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }


            api.get(apiName.LOGOUT_API, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0])
                        }
                        else {
                            this.$emit('success-modal', response?.data?.message);
                            // remove all cookie
                            this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
                            this.$store.dispatch('resetAllState');
                            this.$router.push({ name: 'Home' });
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        siteSettings() {
            return this.$store.getters.siteSettings
        },
        getWalletGetway() {
            this.$router.push({name: 'wallet-gateway'});
            // getWalletGetwayServiceCall();
        }
    }
}
</script>