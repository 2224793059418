<template>
    <div class="register-account">
        <div class="modal fade register-page1" id="register-btn" data-bs-backdrop="static" data-bs-keyboard="false"
            tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="container-fluid">
                            <button @click="refreshData()" type="button" class="btn-close close-top" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div class="row">
                                <div class="col-12 col-md-5">
                                    <div class="text-center">
                                        <img  rel="preload"  class="img-fluid logoregisterPage" :src="siteSettings?.aws_url + siteSettings?.storage_path.domain_image + siteSettings?.logo" alt="logo">
                                        <img  rel="preload"  src="@/assets/images/betfairlogo.webp" class="img-fluid m-top">
                                        <img  rel="preload"  src="@/assets/images/loginImg2.webp" class="img-fluid filterlogin m-top">
                                    </div>
                                </div>
                                <div class="col-12 col-md-7">
                                    <h2 class="regi-acc">{{ translatedLangData('register-account','Register account')}}</h2>
                                    <form class="reg-form">
                                        <div class="get-mob" :disabled="otpSent" :class="{ 'get-mob': !otpSent, 'get-mob-dis': otpSent }">
                                            <div class="col-auto">
                                                <select v-model="form.code" class="form-select form-control" id="autoSizingSelect" :disabled="otpSent"
                                                    :class="{ 'enabled': !otpSent, 'disabled': otpSent }">
                                                    <option :value="allowedCountry.phonecode"
                                                        v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                        :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                                </select>
                                            </div>
                                            <div class="inp-img">
                                                <img  rel="preload"   src="@/assets/images/mob.webp" alt="">
                                            </div>
                                            <input v-model="form.mobileNo" type="tel" minlength="10" maxlength="10" :disabled="otpSent"
                                                class="form-control add" id="autoSizingInput" :placeholder="translatedLangData('enter-phone-number','Enter Phone Number') + '*'"
                                                @keypress="specialCharValidation($event)" @input="handleInput($event)"
                                                :class="{ 'enabled': !otpSent, 'disabled': otpSent }">
                                        </div>
                                        <div>
                                            <p v-if="showErrorMobile && form.mobileNo == ''" class="wrong-message mt-1">
                                                {{ translatedLangData('please-enter-mobile-number','Please Enter Phone Number')}}.
                                            </p>
                                        </div>
                                        <div v-if="showErrorMobileLength">
                                            <p class="wrong-message mt-1">{{ translatedLangData('Please-Enter-Valid-phone-Number', 
                                                'Please Enter Valid Phone Number')}}.
                                            </p>
                                        </div>

                                        <span v-if="timerRunning" class="otp-timer">{{ formatTime(minutes) + ':' + formatTime(seconds) }}</span>
                                        <div class="get-mob for">
                                            <input v-model="form.otp" type="text" class="form-control" @input="validateOTP"
                                            :placeholder="translatedLangData('enter-otp','Enter OTP') + '*'" :disabled="!isOtpApiCalled" maxlength="6">
                                            <div class="inp-img"><img  rel="preload"   src="@/assets/images/key.webp" alt=""></div>
                                            <div class="get-code">
                                                <button class="loader-btn" :disabled="loadingOtp || timerRunning" type="button" @click="sendOtpCall()"
                                                    :class="{ 'btn-loading': loadingOtp }">
                                                    {{ resendOtp ? translatedLangData('resend-otp' , 'Resend OTP') : translatedLangData('get-otp' , 'Get OTP') }}
                                                    <span><b></b><b></b><b></b></span>
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            <p v-if="showValidationError && getOtp() == ''" class="wrong-message mt-1">
                                               {{ translatedLangData('please-enter-otp','Please Enter OTP')}}.</p>
                                        </div>

                                        <div class="get-mob for">
                                            <input v-model="form.userName" type="text" class="form-control" @input="filterSpaces"
                                                :placeholder="translatedLangData('enter-user-id','Enter User ID') + '*'" minlength="6" maxlength="25" 
                                                :disabled="isDisabled" :class="{ 'disabled-input': isDisabled }">
                                            <div class="inp-img"><img  rel="preload"   src="@/assets/images/user-icon.webp" alt=""></div>
                                        </div>
                                        <div>
                                            <p v-if="showValidationError && form.userName == ''" class="wrong-message mt-1">
                                                {{ translatedLangData('please-enter-user-id','Please Enter User ID')}}.
                                            </p>
                                            <p v-if="showUserIDError && form.userName.length > 0" class="wrong-message mt-1">
                                                {{ translatedLangData('userid-must-contain-alphanumeric-and-between-6-to-25-characters', 
                                                'User ID Must Contain Alphanumeric And Between 6 To 25 Characters.') }}
                                            </p>
                                        </div>

                                        <div class="get-mob for">
                                            <input v-model="form.password" :type="passwordFieldType" class="form-control" minLength="8" 
                                                maxlength="20" :placeholder="translatedLangData('enter-pass','Enter Password') + '*'" @input="validatePassword"
                                                :disabled="isDisabled" :class="{ 'disabled-input': isDisabled }">
                                            <div class="inp-img"><img  rel="preload"   src="@/assets/images/pass.webp" alt=""></div>
                                            <div class="eye-icn-sec" @click="togglePasswordVisibility('password')" v-if="!isDisabled">
                                                <img  rel="preload"  v-if="passwordFieldType === 'password'" src="@/assets/images/eye-close.png">
                                                <img  rel="preload"  v-else src="@/assets/images/eye-open.webp" alt="">
                                            </div>
                                        </div>
                                        <div>
                                            <p v-if="showValidationError && form.password == ''" class="wrong-message mt-1">
                                                {{ translatedLangData('please-enter-password','Please Enter Password')}}.</p>
                                            <p v-if="passwordCapitalCaseValidation && form.password.length > 0" class="wrong-message mt-1">
                                                {{ translatedLangData('password-capital-case', 'Must Contain At Least 1 In Capital Case.') }}
                                            </p>
                                            <p v-if="passwordValidation && form.password.length > 0" class="wrong-message mt-1">
                                                {{translatedLangData('must-between-8-20-characters-and-should-be-alph','Must Be Between 8-20 Characters And Should Be Alphanumeric.')}}
                                            </p>
                                        </div>

                                        <div class="get-mob for">
                                            <input v-model="form.confirmPassword" :type="confPasswordFieldType" class="form-control"
                                            :placeholder="translatedLangData('enter-conf-password','Enter Confirm Password') + '*'" @input="validateConfirmPassword" @keyup="checkPasswordMatch"
                                                :disabled="isDisabled" :class="{ 'disabled-input': isDisabled }" maxlength="20" minlength="8">
                                            <div class="inp-img"><img  rel="preload"   src="@/assets/images/pass.webp" alt=""></div>
                                            <div class="eye-icn-sec" @click="togglePasswordVisibility" v-if="!isDisabled">
                                                <img  rel="preload"  v-if="confPasswordFieldType === 'password'" src="@/assets/images/eye-close.png">
                                                <img  rel="preload"  v-else src="@/assets/images/eye-open.webp">
                                            </div>
                                        </div>
                                        <div>
                                            <p v-if="showValidationError && form.confirmPassword == ''" class="wrong-message mt-1">
                                                {{translatedLangData('please-enter-confirm-password','Please Enter Confirm Password')}}.
                                            </p>
                                            <p v-if="confirmPasswordValidation && (form.password != form.confirmPassword) && form.confirmPassword.length > 0" class="wrong-message mt-1">
                                                {{ translatedLangData('password-and-confirm-password-not-matched','Password Mismatch')}}.
                                            </p>
                                        </div>

                                        <div class="get-mob for">
                                            <input v-model="form.referCode" type="text" class="form-control"
                                                :placeholder="translatedLangData('enter-referral-code-optional', 'Enter Referral Code (Optional)')" @keypress="checkEmptyField()"
                                                @input="validateReferCode" :disabled="isDisabled || hasUrlReferer" :class="{ 'disabled-input': isDisabled || hasUrlReferer }">
                                            <div class="inp-img"><img  rel="preload"   src="@/assets/images/reff.webp" alt=""></div>
                                        </div>
                                        <div>
                                            <p v-if="showReferCodeError" class="wrong-message mt-1">
                                                {{ translatedLangData('referral-code-contain-only-numbers', 'Referral Code Must Contain Only Numbers') }}.
                                            </p>
                                        </div>

                                        <button type="button" @click="signUpCall()" class="btn btn-register loader-btn" :disabled="!isFormValid"
                                            :class="{ 'btn-loading': loadingSignUp }">
                                            {{ translatedLangData('register','Register')}}
                                            <span><b></b><b></b><b></b></span>
                                        </button>
                                                <h3 class="whats-with">
                                                    {{translatedLangData('get-your-ready-made-id-from-whatsapp','Get Your Ready-Made ID From Whatsapp')}}
                                                </h3>
                                        <div v-if="siteSettings?.social_link?.whatsappno1" class="button-whatsapp">
                                            <a :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1" class="btn-whatsapp" target="_blank">
                                                <img  rel="preload"  src="@/assets/images/whatsapp.webp" alt="">
                                                    {{ translatedLangData('whatsapp-now','Whatsapp Now')}}
                                            </a>
                                        </div>
                                        <p class="whats-with-acc">{{ translatedLangData('already-have-an-account','Already Have An Account')}}?&nbsp;
                                            <a ref="open_login_modal" data-bs-toggle="modal" href="#staticBackdrop-one" @click="refreshData()">
                                                {{ translatedLangData('login','Login')}}
                                            </a>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../services/api';
import * as apiName from '../services/urls';
import { Modal } from "bootstrap";

export default {
    name: "SignUp",
    inject:['translatedLangData'],
    data() {
        return {
            form: {
                code: this.siteSettings && this.siteSettings.cncode ? this.siteSettings.cncode : "91",
                mobileNo: "",
                otp: [],
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: this.getQueryParam("refer_code", window.location.href),
                captchaText: ''
            },
            showValidationError: false,
            showErrorMobile: false,
            passwordValidation: false,
            loadingSignUp: false,
            loadingOtp: false,
            captchaData: null,
            isOtpApiCalled: false,
            resendOtp: false,
            timerRunning: false,
            minutes: 2,
            seconds: 0,
            passwordFieldType: 'password',
            confPasswordFieldType: 'password',
            showErrorMobileLength: false,
            showUserIDError: false,
            showReferCodeError:false,
            confirmPasswordValidation: false,
            otpSent: false, // Added variable to track OTP sent status
            isDisabled: true,
            passwordCapitalCaseValidation: false,
            hasUrlReferer: false
        };
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        },
        isFormValid() {
            return this.getOtp() && this.form.userName && this.form.password && this.form.confirmPassword;
        }
    },
    created() {
        if((window.location.href).includes('refer_code')) {
            this.hasUrlReferer = true;
        }  
    },
    mounted(){
        if(this.$route.name == 'sign-up' && !this.checkIsLogin() && this.siteSettings?.business_type == 2){
            setTimeout(()=> {
                let loginModal = new Modal('#register-btn');
                loginModal.show();
            }, 1000)
            this.$router.push({name: 'Home'});
        }
        else if(this.$route.name == 'sign-up' && (this.checkIsLogin() || this.siteSettings?.business_type == 1)){
            this.$router.push({name: 'Home'});
        }
    },
    methods: {
        refreshData() {
            this.form = {
                code: 91,
                mobileNo: "",
                otp: '',
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: this.getQueryParam("refer_code", window.location.href),
                captchaText: ''
            },
                this.showValidationError = false,
                this.showErrorMobile = false,
                this.passwordValidation = false,
                this.isOtpApiCalled = false,
                this.resendOtp = false,
                this.resendOtpTimer = 0,
                this.timerRunning = false,
                this.minutes = 0,
                this.seconds = 0,
                this.showErrorMobileLength = false,
                this.showUserIDError = false,
                this.confirmPasswordValidation = false,
                this.otpSent = false,
                this.passwordCapitalCaseValidation = false,
                this.passwordFieldType = 'password',
                this.confPasswordFieldType = 'password'
        },
        sendOtpCall() {
            if (!this.form.mobileNo) {
                this.showErrorMobile = true;
                return false;
            } else if(this.form.mobileNo.length != 10) {
                this.showErrorMobileLength = true;
                return false;
            }
            this.sendOtpServiceCall();
        },
        async sendOtpServiceCall() {
            this.loadingOtp = true;
            let data = {
                "mobile_number": this.form.mobileNo,
                "cncode": this.form.code
            };
            api.post(apiName.SEND_OTP, data).then(response => {
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.isOtpApiCalled = true;
                        this.otpSent = true; // OTP sent successfully, disable phone number field
                        this.isDisabled = false;
                        this.form.captchaText = '';
                        this.showErrorMobile = false;
                        this.showSuccessModalFunc(response.data.message)
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0;
                        this.showErrorMobileLength = false;
                        this.showUserIDError = false;
                        this.setResendTimer();
                    }
                    else if (response.status == 0) {
                    }
                }
            }).catch(error => {
                this.loadingOtp = false;
                this.showErrorModalFunc(error[0])
            });
        },
        signUpCall() {
            if (!this.isOtpApiCalled)
                return
            if (!this.loadingSignUp) {
                let o = this.getOtp();
                this.passwordValidation = false;
                if (this.form.mobileNo == "") {
                    this.showErrorMobile = true;
                }
                else if (o == '' || this.form.userName == '' || this.form.password == '' || this.form.confirmPassword == '' || this.form.password != this.form.confirmPassword) {
                    this.showValidationError = true;
                }
                else {
                    this.signUpServiceCall();
                }
            }
        },
        async signUpServiceCall() {
            this.loadingSignUp = true;
            let isDownLine = this.getQueryParam("downline", window.location.href);
            let data = {
                "userid": this.form.userName,
                "phone": this.form.mobileNo,
                "password": this.form.password,
                "otp": this.getOtp(),
                "isDownLine": isDownLine ? isDownLine : false,
                "refer_code": this.form.referCode,
                "cncode": this.form.code,
            };
            api.post(apiName.REGISTER, data).then(response => {
                this.loadingSignUp = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        }
                        else {
                            this.showSuccessModalFunc(response.data.message);
                            this.refreshData();
                            this.$refs.open_login_modal.click();
                        }
                    }
                }
            }).catch(error => {
                this.loadingSignUp = false;
                if (error)
                    this.showErrorModalFunc(error[0]);
            });
        },
        setResendTimer() {
            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };
            setTimeout(countdown, 1000);
        },
        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },
        getQueryParam(paramName, url) {
            if (!url) url = location.href;
            paramName = paramName.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var regexS = "[\\?&]" + paramName + "=([^&#]*)";
            var regex = new RegExp(regexS);
            var results = regex.exec(url);
            return results == null ? null : results[1];
        },
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
         },
         specialCharValidation(e) {
            if (e.keyCode >= 48 && e.keyCode <= 57) {
                return false;
            }
            const notAllowedKeys = ["e", "E", ".", "x", "X"];
            const keyPressed = String.fromCharCode(e.keyCode);

            if (notAllowedKeys.includes(keyPressed)) {
                e.preventDefault();
            }
        },
        handleInput(event) {
            this.form.mobileNo = event.target.value.replace(/\D/g, ''); // Extract only numeric characters from the input value
            if (this.form.mobileNo.length < 10 && this.form.mobileNo.length > 0) {
                this.showErrorMobileLength = true;
            } else {
                this.showErrorMobileLength = false;
            }
        },
        filterSpaces(event) {
            this.checkEmptyField();
            this.form.userName = event.target.value.replace(/[^\w]|_/g, '');
            const minLength = 6;
            const maxLength = 25;
            const containsAlphabetsAndNumbers = /^(?=.*[a-zA-Z])(?=.*\d).*$/.test(this.form.userName);
            const containsSpaces = /\s/.test(this.form.userName); 

            if (containsSpaces) {
                this.form.userName = this.form.userName.replace(/\s/g, '');
            }
            this.showUserIDError = this.form.userName.length < minLength || this.form.userName.length > maxLength || !containsAlphabetsAndNumbers;
        },
        checkEmptyField() {
            if(this.form.userName == "" || this.form.password == "" || this.form.confirmPassword == "") {
                this.showValidationError = true;
            }
        },
        validatePassword() {
            this.checkEmptyField();
            this.form.password = this.form.password.replace(/[^a-zA-Z0-9]/g, '');
            const password = this.form.password;
            const minLength = 8;
            const maxLength = 20;
            const alphanumericRegex = /^(?=.*[a-zA-Z])(?=.*\d).*$/;
            const hasCapitalLetter = /[A-Z]/.test(password);

            // Combined boolean expressions to set passwordValidation
            this.passwordValidation = password.length < minLength || password.length > maxLength || !alphanumericRegex.test(password);

            // Boolean expression to set passwordCapitalCaseValidation
            this.passwordCapitalCaseValidation = !hasCapitalLetter;

            // Reset passwordCapitalCaseValidation if passwordValidation is true
            if (this.passwordValidation) {
                this.passwordCapitalCaseValidation = false;
            }
        },
        validateOTP() {
            this.form.otp = this.form.otp.replace(/[^0-9]/g, '');
        },
        togglePasswordVisibility(fieldName) {
            if(fieldName == 'password'){
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            } else {
                this.confPasswordFieldType = this.confPasswordFieldType === 'password' ? 'text' : 'password';
            }
        },
        validateReferCode() {
            this.form.referCode = this.form.referCode.replace(/[^a-zA-Z0-9]/g, '');
            const numericRegex = /^\d+$/;
            if (this.form.referCode.length > 2 && !this.form.referCode.match(numericRegex)) {
                this.showReferCodeError = true;
            } else {
                this.showReferCodeError = false;
            }
        },
        validateConfirmPassword(){
            this.form.confirmPassword = this.form.confirmPassword.replace(/[^a-zA-Z0-9]/g, '');
        },
        checkPasswordMatch(event) {
            let confirmPasswordVal = this.form.confirmPassword;
            if(confirmPasswordVal.length > 0) {
                confirmPasswordVal = event.target.value.replace(/\s/g, '');
                if (this.form.confirmPassword != this.form.password) 
                this.confirmPasswordValidation = true;
                else 
                this.confirmPasswordValidation = false;
            } else {
                this.confirmPasswordValidation = false;
            }
        },
    }
};
</script>

<style>
.form-control.refer-code:disabled {
    background-color: #a8a2a2;
    opacity: 1;
}
</style>

