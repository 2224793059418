const BetsModule = () => import(/* webpackChunkName: "bets-module" */ './views/Module.vue'); 
const Bets = () => import(/* webpackChunkName: "bets" */ './views/Bets.vue');
const Statements = () => import(/* webpackChunkName: "statements" */ './views/Statements.vue');
const ProfitLoss = () => import(/* webpackChunkName: "profit-loss" */ './views/ProfitLoss.vue');
const ProfitLossDetail = () => import(/* webpackChunkName: "profit-loss" */ './views/ProfitLossDetail.vue');
const BetHistory = () => import(/* webpackChunkName: "profit-loss" */ './views/BetHistory.vue');
const SettledBets = () => import(/* webpackChunkName: "bets" */ './views/SettledBets.vue');
const CasinoWinLossDetails = () => import(/* webpackChunkName: "win-loss" */ './components/CasinoWinLossDetail.vue');

const BetsRoutes =   {
    path: '/',
    component: BetsModule,
    children: [
      {
        path: 'bets',
        name: 'bets',
        component: Bets
      },
      {
        path: 'statements',
        name: 'statements',
        component: Statements
      },
      {
        path: 'profit-loss',
        name: 'profit-loss',
        component: ProfitLoss
      },
      {
        path: 'profit-loss-detail',
        name: 'profitLossDetail',
        component: ProfitLossDetail
      },
      {
        path: 'bet-history',
        name: 'betHistory',
        component: ProfitLossDetail
      },
      {
        path: 'settled-bets',
        name: 'settled-bets',
        component: SettledBets
      },
      {
        path: 'casino-win-loss-details',
        name: 'casino-win-loss-details',
        component: CasinoWinLossDetails,
    }
    ],
  }

export default BetsRoutes;