const state = {
    depositAmount: 0
};
const getters = {
    depositAmount:state => state?.depositAmount,
};
const actions = {
    setDepositAmount({ commit }, depositAmount) {
        commit('setDepositAmount', depositAmount);
    },
};
const mutations = {
    setDepositAmount(state, depositAmount){
        state.depositAmount =  depositAmount
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};