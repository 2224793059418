
import App from "./App.vue";
import router from "./router"
import store from "./store";
import VueCookies from 'vue3-cookies';
import { createApp } from 'vue'
import "./assets/css/owl.carousel.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/style1.css";
import "./assets/css/responsive.css";
import "./assets/fontawesome/css/all.css"
import "bootstrap";
import 'vue3-carousel/dist/carousel.css'
import "vue-gtranslate/translator.css"
import './registerServiceWorker'
import VueAwesomePaginate from "vue-awesome-paginate";

router.beforeEach((to, from,next) => {
    if(window.store?.getters.isAuthenticated && !window.store?.getters?.stateUser?.last_login_at && to.name != 'change-password'){
        window.router.push({ name: 'change-password'});
    }
    if(document.querySelector('.modal.show')) {
        next(false);
    } else {
        next();
    }
})

window.addEventListener('popstate', () => {
    if(document.querySelector('.modal.show')) {
        window.location.reload();
    }
});

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueCookies);
app.use(VueAwesomePaginate);
app.mount("#app");

let isLogin = store.getters.isAuthenticated;
let routeArray = ['multi-market', 'mobile-account', 'statements']
let routeName = window.location.hash.split('/')[1];
if(!isLogin && routeArray.includes(routeName)) {
    window.router.push({name: 'Home'});
}

app.config.globalProperties.fantasy_server = "fantasycricket";
app.config.globalProperties.click_server = "fantasy";