const MultiMarketModule = () => import(/* webpackChunkName: "multi-market-module" */ './views/Module.vue');
const MultiMarket = () => import(/* webpackChunkName: "multi-market" */ './views/MultiMarket.vue');

const MultiMarketRoutes = {
    path: '/',
    component: MultiMarketModule,
    children: [
        {
            path: 'multi-market',
            name: 'multi-market',
            component: MultiMarket
        }
    ],
}

export default MultiMarketRoutes;