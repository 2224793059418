const SportsModule = () => import(/* webpackChunkName: "sports-module" */ './views/Module.vue');
const Sports = () => import(/* webpackChunkName: "sports" */ './views/Sports.vue');
const SportEventDetail = () => import(/* webpackChunkName: "sport-event-detail" */ './views/SportEventDetail.vue');
const InPlay = () => import(/* webpackChunkName: "sport-event-detail" */ './views/InPlay.vue');
const SportsBook = () => import(/* webpackChunkName: "sport-book" */ './views/SportsBook.vue');

const SportsRoutes = {
	path: '/',
	component: SportsModule,
	children: [
		{
			path: 'sports/:type/:id/:leagueName?',
			name: 'sports',
			component: Sports
		},
		{
			path: 'inplay',
			name: 'inplay',
			component: InPlay
		},
		{
			path: 'sports-event-detail/:event_id',
			name: 'sports-event-detail',
			component: SportEventDetail
		},
		{
			path: 'sports-book',
			name: 'sports-book',
			component: SportsBook
		}
	],
}

export default SportsRoutes;