const BonusModule = () => import(/* webpackChunkName: "bonus-module" */ './views/Module.vue');
const Bonus = () => import(/* webpackChunkName: "bonus" */ './views/Bonus.vue');

const BonusRoutes = {
    path: '/',
    component: BonusModule,
    children: [
        {
            path: 'bonus',
            name: 'bonus',
            component: Bonus,
        }
    ],
}

export default BonusRoutes;