<template>
    <!--<section class="marquee-box" v-if="siteSettings && siteSettings.announcement && siteSettings.announcement.msg">
        <h4><img  rel="preload"  src="@/assets/images/microphone.webp" alt="microphone"> News </h4>
        <marquee :style="{ color: siteSettings?.announcement?.color_code }" scrollamount="5">{{siteSettings.announcement.msg}}</marquee>
    </section> -->
    <section class="main-navbar" >
        <div class="topnav">
            <div class="container-fluid">
                <div class="market-nav flex-d">
                    <div class="lef-nav">
                        <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
                            <div id="topnav-menu-content" class="collapse navbar-collapse">
                                <ul class="navbar-nav">
                                    <li class="nav-item">
                                        <router-link to="/dashboard">{{ translatedLangData('home','Home')}}</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/inplay">{{ translatedLangData('inplay','In-Play')}}</router-link>
                                    </li>
                                    <li v-for="(sport,n_index) in non_custom_sports" :key="n_index" class="nav-item">
                                        <router-link :to="'/sports/' + sport.slug + '/' + sport.id">{{ translatedLangData(sport.slug,sport.name)}}</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/casino" class="hightlight-menus">{{ translatedLangData('casino','Casino')}}</router-link>
                                    </li>
                                    <li class="nav-item" v-if="checkEventManageforSingleType('sports-book')">
                                        <a href="Javascript:void(0);" class="new-tag-menus sb-menus" @click="goToSportsBook()">
                                            {{ translatedLangData('sportsbook','Sports book')}}
                                            <em>{{ translatedLangData('new','new')}}</em>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/int-casino"  class="hightlight-menus">{{ translatedLangData('int-casino','Int Casino')}}</router-link>
                                    </li>
                                     <li v-for="(sport,c_index) in custom_sports" :key="c_index" class="nav-item">
                                        <router-link :to="'/sports/' + sport.slug + '/' + sport.id">{{ translatedLangData(sport.slug,sport.name)}}</router-link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>

                    <div v-if="checkIsLogin()" class="rig-nav">
                        <ul>
                            <li class="multi-market">
                                <router-link to="/multi-market"> {{ translatedLangData('multi-market','Multi Market')}}</router-link>
                            </li>
                            <li class="cogSetting">
                                <a data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
                                    aria-controls="collapseExample">{{ translatedLangData('setting','Setting')}} <i class="fa-solid fa-gear"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div style="visibility:hidden" ref="login_modal" data-bs-toggle="modal" data-bs-target="#staticBackdrop-one"></div>
</template>

<script>
import click_api from '@/shared/services/click_api';
import * as apiName from '@/shared/services/urls';

export default {
    name: 'NavBar',
    inject:['translatedLangData'],
    data(){
        return{
            loading:false,
            non_custom_sports:[],
            custom_sports:[],
            eventManage: null
        }
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        sortedNonCustomSports() {
            const order = ['cricket', 'football', 'tennis'];
            return this.non_custom_sports.sort((a, b) => {
                const aIndex = order.indexOf(a.name.toLowerCase());
                const bIndex = order.indexOf(b.name.toLowerCase());

                if (aIndex !== -1 || bIndex !== -1) {
                return (aIndex === -1 ? Infinity : aIndex) - (bIndex === -1 ? Infinity : bIndex);
                }
                return a.rank - b.rank;
            });
        } 
    },
    created() {
        this.eventManage = this.$store?.getters?.eventManage;
    },
    mounted(){
        this.get_sportsList();
    },
    methods: {
        checkEventManageforSports(sportId) {
            if(this.checkIsLogin()) {
                let sportEventManage = this.eventManage.find(item => item.type == 'sports');
                if(sportEventManage) {
                    let sportIds = sportEventManage.type_id.split(',');
                    return !sportIds.includes(sportId.toString());
                }
            }
            return true;    
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
        get_sportsList() {
            this.loading = true;
            click_api.get(apiName.GET_SPORTS).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        var all_sports = response.data.data;
                        this.non_custom_sports = [];
                        for (var i = 0; i < all_sports.length; i++) {
                            if (this.checkEventManageforSports(all_sports[i].id)) {
                                if (all_sports[i].is_custom == 0) {
                                    this.non_custom_sports.push(all_sports[i]);
                                } else {
                                    this.custom_sports.push(all_sports[i]);
                                }
                            }
                        }

                        // Update name from 'soccer' to 'football'
                        this.non_custom_sports = this.non_custom_sports.map(sport => {
                            if (sport.name === 'soccer') {
                                return { ...sport, name: 'football' };
                            }
                            return sport;
                        });

                        // Sort non_custom_sports by rank
                        this.non_custom_sports.sort((a, b) => a.rank - b.rank);

                        var sport_list = {};
                        sport_list['custom'] = this.custom_sports;
                        sport_list['non_custom'] = this.sortedNonCustomSports
                        this.$store.dispatch('setSportsList',sport_list)
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error.data.message);
                }
            });
        },
        checkEventManageforSingleType(type) {
            if(this.checkIsLogin()) {
                let sportEventManage = this.eventManage.find(item => item.type == type);
                return !sportEventManage;
            }
            return true;    
        },
        goToSportsBook() {
            if(this.checkIsLogin()) {
                this.$router.push({ name: 'sports-book'});
            } else {
                this.$refs.login_modal.click();
                this.showErrorModalFunc('Please Login to view sportsbook');
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        }
    }
}
</script>