const MarketAnalysisModule = () => import(/* webpackChunkName: "market-analysis-module" */ './views/Module.vue');
const MarketAnalysis = () => import(/* webpackChunkName: "market-analysis" */ './views/MarketAnalysis.vue');

const MarketAnalysisRoutes = {
    path: '/',
    component: MarketAnalysisModule,
    children: [
        {
            path: 'market-analysis',
            name: 'market-analysis',
            component: MarketAnalysis
        }
    ],
}

export default MarketAnalysisRoutes;