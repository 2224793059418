<template>
    <div class="forget-password">
        <div class="modal fade register-page1" id="staticBackdrop-two" data-bs-backdrop="static" data-bs-keyboard="false"
            tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row">
                                <button ref="close_btn" type="button" @click="refreshData()" class="btn-close close-top" 
                                    data-bs-dismiss="modal" aria-label="Close">
                                </button>
                                <div class="col-12 col-md-5">
                                    <div class="text-center">
                                        <img  rel="preload"  class="img-fluid logoregisterPage" :src="siteSettings?.aws_url + siteSettings?.storage_path.domain_image + siteSettings?.logo" alt="logo">
                                        <img  rel="preload"   src="@/assets/images/betfairlogo.webp" class="img-fluid m-top">
                                        <img  rel="preload"   src="@/assets/images/loginImg2.webp" class="img-fluid filterlogin m-top">
                                    </div>
                                </div>
                                <div class="col-12 col-md-7">
                                    <!-- <h2 class="regi-acc">Trouble Logging In?</h2> -->
                                    <h2 class="regi-acc">{{ translatedLangData('forgot-password-cap','FORGOT PASSWORD')}}</h2>
                                    <!-- <p class="create">
                                        Enter your Phone and we'll send <br>
                                        you a otp to get back into your account.
                                    </p> -->
                                    <form class="reg-form">
                                        <div class="get-mob" :disabled="otpSent" :class="{ 'get-mob': !otpSent, 'get-mob-dis': otpSent }">
                                            <div class="col-auto">
                                                <select v-model="form.code" class="form-select form-control" id="autoSizingSelect" :disabled="otpSent"
                                                    :class="{ 'enabled': !otpSent, 'disabled': otpSent }">
                                                    <option :value="allowedCountry.phonecode"
                                                        v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                        :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                                </select>
                                            </div>
                                            <div class="inp-img">
                                                <img  rel="preload"   src="@/assets/images/mob.webp" alt="">
                                            </div>
                                            <input v-model="form.mobileNo" type="tel" minlength="10" maxlength="10" :disabled="otpSent"
                                                class="form-control add" id="autoSizingInput" :placeholder="translatedLangData('enter-phone-number','Enter Phone Number') + '*'"
                                                @keypress="specialCharValidation($event)" @input="handleInput($event)"
                                                :class="{ 'enabled': !otpSent, 'disabled': otpSent }">
                                        </div>
                                        <div>
                                            <p v-if="showErrorMobile && form.mobileNo == ''" class="wrong-message mt-1">
                                                {{ translatedLangData('please-enter-phone-number','Please Enter Phone Number')}}.
                                            </p>
                                            <p v-if="showErrorMobileLength" class="wrong-message mt-1">
                                                {{ translatedLangData('Please-Enter-Valid-phone-Number', 'Please Enter Valid Phone Number')}}.
                                            </p>
                                        </div>

                                        <span v-if="timerRunning" class="otp-timer">{{ formatTime(minutes) + ':' + formatTime(seconds) }}</span>
                                        <div class="get-mob for">
                                            <input v-model="form.otp" type="text" class="form-control" @input="validateOTP"
                                            :placeholder="translatedLangData('enter-otp','Enter OTP') + '*'" :disabled="!isOtpApiCalled" maxlength="6">
                                            <div class="inp-img"><img  rel="preload"   src="@/assets/images/key.webp" alt=""></div>
                                            <div class="get-code">
                                                <button class="loader-btn" :disabled="loadingOtp || timerRunning" type="button" @click="sendOtpCall()"
                                                    :class="{ 'btn-loading': loadingOtp }">
                                                    {{ resendOtp ? translatedLangData('resend-otp' , 'Resend OTP') : translatedLangData('get-otp' , 'Get OTP') }}
                                                    <span><b></b><b></b><b></b></span>
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            <p v-if="showValidationError && getOtp() == ''" class="wrong-message mt-1">
                                               {{ translatedLangData('please-enter-otp','Please Enter OTP')}}.</p>
                                        </div>

                                        <div class="get-mob for">
                                            <input v-model="form.password" :type="passwordFieldType" class="form-control" minLength="8" 
                                                maxlength="20" :placeholder="translatedLangData('enter-pass','Enter Password') + '*'" 
                                                @input="validatePassword" :disabled="isDisabled" :class="{ 'disabled-input': isDisabled }">
                                            <div class="inp-img"><img  rel="preload"   src="@/assets/images/pass.webp" alt=""></div>
                                            <div class="eye-icn-sec" @click="togglePasswordVisibility('password')">
                                                <img  rel="preload"  v-if="passwordFieldType === 'password'" src="@/assets/images/eye-close.png">
                                                <img  rel="preload"  v-else src="@/assets/images/eye-open.webp" alt="">
                                            </div>
                                        </div>
                                        <div>
                                            <p v-if="showValidationError && form.password == ''" class="wrong-message mt-1">
                                                {{ translatedLangData('please-enter-password','Please Enter Password')}}.</p>
                                            <p v-if="passwordCapitalCaseValidation && form.password.length > 0" class="wrong-message mt-1">
                                                {{ translatedLangData('password-capital-case', 'Must Contain At Least 1 In Capital Case.') }}
                                            </p>
                                            <p v-if="passwordValidation && form.password.length > 0" class="wrong-message mt-1">
                                                {{translatedLangData('must-between-8-20-characters-and-should-be-alph','Must Be Between 8-20 Characters And Should Be Alphanumeric.')}}
                                            </p>
                                        </div>

                                        <div class="get-mob for">
                                            <input v-model="form.confirmPassword" :type="confPasswordFieldType" class="form-control"
                                                :placeholder="translatedLangData('enter-conf-password','Enter Confirm Password') + '*'" 
                                                @keypress="checkEmptyField()" @keyup="checkPasswordMatch"
                                                :disabled="isDisabled" :class="{ 'disabled-input': isDisabled }" minLength="8" 
                                                maxlength="20" @input="validateConfirmPassword">
                                            <div class="inp-img"><img  rel="preload"   src="@/assets/images/pass.webp" alt=""></div>
                                            <div class="eye-icn-sec" @click="togglePasswordVisibility">
                                                <img  rel="preload"  v-if="confPasswordFieldType === 'password'" src="@/assets/images/eye-close.png">
                                                <img  rel="preload"  v-else src="@/assets/images/eye-open.webp">
                                            </div>
                                        </div>
                                        <div>
                                            <p v-if="showValidationError && form.confirmPassword == ''" class="wrong-message mt-1">
                                                {{translatedLangData('please-enter-confirm-password','Please Enter Confirm Password')}}.    
                                            </p>
                                            <p v-if="confirmPasswordValidation && (form.password != form.confirmPassword) && form.confirmPassword.length > 0" class="wrong-message mt-1">
                                                {{ translatedLangData('password-and-confirm-password-not-matched','Password Mismatch')}}.
                                            </p>
                                        </div>

                                        <div class="for-pass">
                                            <p class="login-with-acc">{{translatedLangData('remember-your-password','Remember Your Password')}}?&nbsp;
                                                <a data-bs-toggle="modal" class="text-dark" href="#staticBackdrop-one" @click="refreshData()">
                                                    {{translatedLangData('login','Login')}}
                                                </a>
                                            </p>
                                        </div>

                                        <button type="button" @click="forgetPasswordCall()" class="btn btn-register loader-btn" :class="{ 'btn-loading': loadingUpdate }">
                                            {{translatedLangData('update-password','Update Password')}}
                                            <span><b></b><b></b><b></b></span>
                                        </button>
                                        <h3 class="whats-with">{{ translatedLangData('or-continue-with', 'Or Continue With') }} 
                                            <span class="whats-with-now">{{ translatedLangData('whatsapp', 'Whatsapp') }}</span>
                                        </h3>
                                        <div v-if="siteSettings?.social_link?.whatsappno1" class="button-whatsapp">
                                            <a :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1" class="btn-whatsapp" target="_blank">
                                                <img  rel="preload"   src="@/assets/images/whatsapp.webp" alt="">{{ translatedLangData('whatsapp-now', 'Whatsapp Now') }} 
                                            </a>
                                        </div>
                                        <p class="whats-with-acc">{{ translatedLangData('dont-have-an-account', "Don't Have An Account") }}? &nbsp;
                                            <a data-bs-toggle="modal" href="#register-btn" @click="refreshData()">{{translatedLangData('register','Register')}}</a>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../services/api';
import * as apiName from '../services/urls';
import { Modal } from "bootstrap";

export default {
    name: "ForgetPassword",
    inject:['translatedLangData'],
    data() {
        return {
            form: {
                code: 91,
                mobileNo: "",
                otp: '',
                password: "",
                confirmPassword: "",
                captchaText: ''
            },
            loadingOtp: false,
            loadingUpdate: false,
            showValidationError: false,
            showErrorMobile: false,
            passwordValidation: false,
            captchaData:null,
            isOtpApiCalled: false,
            resendOtp: false,
            timerRunning: false,
            minutes: 2,
            seconds: 0,
            passwordFieldType: 'password',
            confPasswordFieldType: 'password',
            showErrorMobileLength: false,
            otpSent: false,
            passwordCapitalCaseValidation : false,
        };
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        }
    },
    mounted(){
        if(this.$route.name == 'forget-password' && !this.checkIsLogin()){
            let loginModal = new Modal('#staticBackdrop-two');
            loginModal.show();
        }
        else if(this.$route.name == 'forget-password' && this.checkIsLogin()){
            this.$router.push('/')
        }
    },
    methods: {
        refreshData() {
            this.form = {
                code: 91,
                mobileNo: "",
                otp: '',
                password: "",
                confirmPassword: "",
                captchaText: ''
            },
                this.showValidationError = false,
                this.showErrorMobile = false,
                this.passwordValidation = false,
                this.isOtpApiCalled = false,
                this.resendOtp = false,
                this.resendOtpTimer = 0,
                this.timerRunning = false,
                this.minutes = 0,
                this.seconds = 0,
                this.showErrorMobileLength = false,
                this.confirmPasswordValidation = false,
                this.otpSent = false,
                this.passwordCapitalCaseValidation = false,
                this.passwordFieldType = 'password',
                this.confPasswordFieldType = 'password'
        },
        sendOtpCall() {
            if (this.form.mobileNo == "") {
                this.showErrorMobile = true;
            }
            else {
                this.sendOtpServiceCall();
            }
        },
        async sendOtpServiceCall() {
            this.loadingOtp = true;
            let data = {
                "mobile_number": this.form.mobileNo,
                "cncode": this.form.code,
                "getUserId": ""
            };
            api.post(apiName.SEND_OTP, data).then(response => {
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.isOtpApiCalled = true;
                        this.form.captchaText = '';
                        this.showSuccessModalFunc(response.data.message);
                        this.showErrorMobile = false;
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0
                        this.setResendTimer();
                        //this.closeModal();
                    }

                }
            }).catch(error => {
                this.loadingOtp = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        forgetPasswordCall() {
            if(this.form.mobileNo == "" || this.form.password == "" || this.form.confirmPassword == "") {
                this.showErrorMobile = true;
                this.showValidationError = true;
            }
            if (!this.isOtpApiCalled)
                return
            let o = this.getOtp();

            this.passwordValidation = false;
            var regex = /^(?=.*?[a-z])(?=.*?[0-9]).{8,20}$/;
            if (this.form.mobileNo == "") {
                this.showErrorMobile = true;
            }else if(!regex.test(this.form.password)){
                this.passwordValidation = true;
            } 
            else if (o.length != 6 || this.form.password == '' || this.form.confirmPassword == '') {
                this.showValidationError = true;
            }
            else if (this.form.password != this.form.confirmPassword) {
                this.passwordValidation = true;
            }
            else {
                this.forgotServiceCall();
            }
        },
        async forgotServiceCall() {
            this.loadingUpdate = true;
            let data = {
                "phone": this.form.mobileNo,
                "password": this.form.password,
                "confirm_password": this.form.confirmPassword,
                "otp": this.getOtp(),
                "cncode": this.form.code
            };
            api.post(apiName.FORGOT_PASSWORD, data).then(response => {
                this.loadingUpdate = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        }
                        else {
                            this.showSuccessModalFunc(response.data.message);
                            this.refreshData();
                            this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                this.loadingUpdate = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        setResendTimer() {
            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };
            setTimeout(countdown, 1000);
        },
        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },
         getQueryParam(paramName, url) {
            if (!url) url = location.href;
            paramName = paramName.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var regexS = "[\\?&]" + paramName + "=([^&#]*)";
            var regex = new RegExp(regexS);
            var results = regex.exec(url);
            return results == null ? null : results[1];
        },
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        closeModal() {
            
            this.$refs.close_btn.click();
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
        validatePassword() {
            this.checkEmptyField();
            this.form.password = this.form.password.replace(/[^a-zA-Z0-9]/g, '');
            const password = this.form.password;
            const minLength = 8;
            const maxLength = 20;
            const alphanumericRegex = /^(?=.*[a-zA-Z])(?=.*\d).*$/;
            const hasCapitalLetter = /[A-Z]/.test(password);

            // Combined boolean expressions to set passwordValidation
            this.passwordValidation = password.length < minLength || password.length > maxLength || !alphanumericRegex.test(password);

            // Boolean expression to set passwordCapitalCaseValidation
            this.passwordCapitalCaseValidation = !hasCapitalLetter;

            // Reset passwordCapitalCaseValidation if passwordValidation is true
            if (this.passwordValidation) {
                this.passwordCapitalCaseValidation = false;
            }
        },
        specialCharValidation(e) {
            if (e.keyCode >= 48 && e.keyCode <= 57) {
                return false;
            }
            const notAllowedKeys = ["e", "E", ".", "x", "X"];
            const keyPressed = String.fromCharCode(e.keyCode);

            if (notAllowedKeys.includes(keyPressed)) {
                e.preventDefault();
            }
        },
        handleInput(event) {
            this.form.mobileNo = event.target.value.replace(/\D/g, ''); // Extract only numeric characters from the input value
            if (this.form.mobileNo.length < 10 && this.form.mobileNo.length > 0) {
                this.showErrorMobileLength = true;
            } else {
                this.showErrorMobileLength = false;
            }
        },
        validateOTP() {
            this.form.otp = this.form.otp.replace(/[^0-9]/g, '');
        },
        togglePasswordVisibility(fieldName) {
            if(fieldName == 'password'){
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            } else {
                this.confPasswordFieldType = this.confPasswordFieldType === 'password' ? 'text' : 'password';
            }
        },
        validateConfirmPassword() {
            this.form.confirmPassword = this.form.confirmPassword.replace(/[^a-zA-Z0-9]/g, '');
        },
        checkPasswordMatch(event) {
            let confirmPasswordVal = this.form.confirmPassword;
            if(confirmPasswordVal.length > 0) {
                confirmPasswordVal = event.target.value.replace(/\s/g, ''); // Remove any spaces from the input
                if (confirmPasswordVal != this.form.password) {
                    this.confirmPasswordValidation = true;
                } 
            } else {
                this.confirmPasswordValidation = false;
            }
        },
        checkEmptyField() {
            if(this.form.password == "" || this.form.confirmPassword == "") {
                this.showValidationError = true;
            }
        }
    }
};
</script>