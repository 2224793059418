<template>
    <div class="stake">
        <div class="modal fade" id="staticBackdrop-stake" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">
                            <i class="fa-sharp fa-solid fa-gear"></i>
                            &nbsp;{{ translatedLangData('setting', 'Setting') }} 
                        </h5>
                        <button type="button" @click="resetChips()" ref="close_button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="stakediv">
                            <h3>{{ translatedLangData('stake', 'Stake') }}</h3>
                            <dl v-if="chips" class="setting-block stake-setting">
                                <dd>
                                    <input maxlength="8" @change="chips.chip_name_1 = getKFormatter(chips.chip_val_1)"
                                        :value="chips.chip_val_1" @input="handleInput($event, 'chip_val_1')"
                                        class="btn-thm m-auto">
                                </dd>
                                <dd>
                                    <input maxlength="8" @change="chips.chip_name_2 = getKFormatter(chips.chip_val_2)"
                                        :value="chips.chip_val_2" @input="handleInput($event, 'chip_val_2')"
                                        class="btn-thm m-auto">
                                </dd>
                                <dd>
                                    <input maxlength="8" @change="chips.chip_name_3 = getKFormatter(chips.chip_val_3)"
                                        :value="chips.chip_val_3" @input="handleInput($event, 'chip_val_3')"
                                        class="btn-thm m-auto">
                                </dd>
                                <dd>
                                    <input maxlength="8" @change="chips.chip_name_4 = getKFormatter(chips.chip_val_4)"
                                        :value="chips.chip_val_4" @input="handleInput($event, 'chip_val_4')"
                                        class="btn-thm m-auto">
                                </dd>
                                <dd>
                                    <input maxlength="8" @change="chips.chip_name_5 = getKFormatter(chips.chip_val_5)"
                                        :value="chips.chip_val_5" @input="handleInput($event, 'chip_val_5')"
                                        class="btn-thm m-auto">
                                </dd>
                                <dd>
                                    <input maxlength="8" @change="chips.chip_name_6 = getKFormatter(chips.chip_val_6)"
                                        :value="chips.chip_val_6" @input="handleInput($event, 'chip_val_6')"
                                        class="btn-thm m-auto">
                                </dd>
                                <dd>
                                    <input maxlength="8" @change="chips.chip_name_7 = getKFormatter(chips.chip_val_7)"
                                        :value="chips.chip_val_7" @input="handleInput($event, 'chip_val_7')"
                                        class="btn-thm m-auto">
                                </dd>
                                <dd>
                                    <input maxlength="8" @change="chips.chip_name_8 = getKFormatter(chips.chip_val_8)"
                                        :value="chips.chip_val_8" @input="handleInput($event, 'chip_val_8')"
                                        class="btn-thm m-auto">
                                </dd>
                                <dd class="col-stake_edit">
                                    <a @click="callChangeStakeValue()" class="btn-send ui-link">{{ translatedLangData('save', 'Save') }}</a>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../services/api';
import * as apiName from '../services/urls';
import { kFormatter } from '@/shared/utils/formatter';

export default {
        name: "StackModal",
        inject:['translatedLangData'],
        data() {
            return {
                chips: null,
                chipsDummy: null,
                userData: null
            }
        },
        mounted() {
            this.chips = {...this.$store?.getters?.chips};
            this.chipsDummy = {...this.$store?.getters?.chips};
            this.userData = this.$store?.getters?.stateUser;
        },
        methods: {
            handleInput(event, fieldName) {
                let inputValue = event.target.value;
                if (inputValue.length === 1 && inputValue === '0') {
                    this.chips[fieldName] = '';
                    event.target.value = '';
                } else if (event.data == '0' && inputValue.startsWith('0')) {
                    event.target.value = Number(this.chips[fieldName]);
                }else if (/^\d+$/.test(inputValue)) {
                    this.chips[fieldName] = Number(inputValue);
                } else {
                    if (inputValue !== '') {
                        event.target.value = Number(this.chips[fieldName]);
                    } else {
                        this.chips[fieldName] = '';
                    }
                }
            },
            callChangeStakeValue() {
                if (!this.validateChipValues()) {
                    return false;
                }

                let chipsData = {
                    "chips": {
                        ...this.chips
                    }
                }
                let headers = {
                    'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                }
                this.loading = true;
                api.post(apiName.CHANGE_STAKE_VALUES, chipsData, { headers }).then(response => {
                    this.loading = false;
                    if (response && response.status == 200) {
                        this.$store.dispatch('setChips', this.chips);
                        this.showSuccessModalFunc('Saved Successfully.')
                        this.$emit('save-btn');
                        this.$refs.close_button.click();
                        this.resetChips();
                    } else {

                    }
                }).catch(error => {
                    this.loading = false;
                    if (error) {
                        this.showErrorModalFunc(error[0]);
                    }
                });
            },
            getKFormatter(num) {
                return kFormatter(num);
            },
            resetChips(){
                this.chips = {...this.$store?.getters?.chips};
            },
            validateChipValues() {
                const chipValueKeys = Object.keys(this.chips).filter(key => key.startsWith('chip_val_'));
                const uniqueChipValues = new Set();
                const length = chipValueKeys.length;
                for (let i = 0; i < length; i++) {
                    const chipValue = parseFloat(this.chips[chipValueKeys[i]]);
                    if (this.chips[chipValueKeys[i]] === null || this.chips[chipValueKeys[i]] === "") {
                        this.$emit('error-modal', 'Stake Field Is Required.');
                        return false;
                    }
                    if (isNaN(chipValue) || chipValue !== Math.floor(chipValue) || chipValue < 100 || chipValue > 10000000) {
                        let errorMessage = "";
                        if (isNaN(chipValue) || chipValue !== Math.floor(chipValue)) {
                            errorMessage += 'Value Must Be A Whole Number.';
                        }
                        if (chipValue < 100) {
                            errorMessage += 'The Stake Must At Least 100.';
                        }
                        if (chipValue > 10000000) {
                            errorMessage += 'Value Must Be Less Than OR Equal To 1 Crore.';
                        }
                        this.$emit('error-modal', errorMessage.trim());
                        return false;
                    }
                    if (uniqueChipValues.has(chipValue)) {
                        this.$emit('error-modal', 'Edit Stake Fields should Not Accept Duplicate Values.');
                        return false;
                    }
                    uniqueChipValues.add(chipValue);
                }
                return true;
            },
            showErrorModalFunc(message) {
				this.$emit('error-modal', message);
			},
			showSuccessModalFunc(message) {
				this.$emit('success-modal', message);
			}
        },
        emits: ['close-stake-modal', 'success-modal', 'error-modal', 'save-btn']
};
</script>