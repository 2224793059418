<template>
    <div class="modal fade modal-footer-sticky" id="changepasswordModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog  login-modal-res">
            <div class="modal-content login-modal-sec">
                <div class="modal-header">
                    <div class="thm-heading">
                        <h3>Change password</h3>
                    </div>
                    <button ref="close_btn" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <form>
                        <div class="password-sec-new mt-lg-3 mt-2"
                            :class="{ 'password-wrong': (showValidationError && oldPassword == '') }">
                            <label for="exampleInputEmail1" class="form-label">Old Password</label>
                            <div class=" flex-nowrap position-relative">
                                <input type="password" v-model="oldPassword" class="form-control bg-transparent"
                                    placeholder="Enter here" aria-label="Username" aria-describedby="addon-wrapping">
                            </div>
                            <div class="thm-heading justify-content-start mt-2    password-wrong-con">
                                <p class="wrong-color">Please enter old password.</p>
                            </div>
                        </div>

                        <div class="password-sec-new mt-lg-3 mt-2"
                            :class="{ 'password-wrong': (showValidationError && newPassword == '') }">
                            <label for="exampleInputEmail1" class="form-label">New Password</label>
                            <div class=" flex-nowrap position-relative">
                                <input type="password" v-model="newPassword" class="form-control bg-transparent"
                                    placeholder="New Password " aria-label="Username" aria-describedby="addon-wrapping">
                            </div>
                            <div class="thm-heading justify-content-start mt-2    password-wrong-con">
                                <p class="wrong-color">Please enter new password.</p>
                            </div>
                        </div>

                        <div class="password-sec-new  mt-lg-3 mt-2"
                            :class="{ 'password-wrong': ((showValidationError && confirmPassword == '') || (passwordValidation && newPassword!=confirmPassword)) }">
                            <label for="exampleInputEmail1" class="form-label">Confirm Password</label>
                            <div class=" flex-nowrap position-relative">
                                <input type="password" v-model="confirmPassword" class="form-control bg-transparent"
                                    placeholder="Confirm Password" aria-label="Username" aria-describedby="addon-wrapping">
                            </div>
                            <div class="thm-heading justify-content-start mt-2    password-wrong-con">
                                <p v-if="confirmPassword == ''" class="wrong-color">Please enter confirm password.
                                </p>
                                <p v-else-if="newPassword!=confirmPassword" class="wrong-color">Password and confirm password not matched.</p>
                            </div>
                        </div>

                        <div class="back-modal-footer mt-lg-4 mt-2">
                            <a class=" place-but thm-bor-but w-50 text-center " data-bs-dismiss="modal">Cancel</a>
                            <a @click="submitChangePassword()" class="btn thm-bor-but w-50  text-center me-0">Save</a>
                        </div>
                    </form>

                </div>

            </div>
        </div>
    </div>
</template>
<script>
import api from '../services/api';
import * as apiName from '../services/urls';
import { AUTH_TOKEN } from "@/shared/constants/cookies-const";

export default {
    name: 'ChangePassword',
    data() {
        return {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            showValidationError: false,
            passwordValidation: false,
        }
    },
    methods: {
        submitChangePassword() {
            if (this.oldPassword == '' || this.newPassword == '' || this.confirmPassword == '') {
                this.showValidationError = true;
            }
            else if (this.newPassword != this.confirmPassword) {
                this.passwordValidation = true;
            }
            else {
                this.callChangePasswordServiceFunction();
            }
        },
        callChangePasswordServiceFunction() {
            
            this.loading = true;

            let headers = {
                'Authorization':`Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            
            let data = {
                "current_password": this.oldPassword,
                "new_password": this.newPassword,
                "confirm_password": this.confirmPassword
            };
            api.post(apiName.CHANGE_MY_PASSWORD, data,{headers}).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal',response.data.debug[0]);
                        }
                        else {

                            this.$emit('success-modal',response.data.message);
                            //let userData = response.data.data;
                            // this.$cookies.set(AUTH_TOKEN, userData.token);
                            // axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                            // this.setUserDataToStore(userData);
                            // this.refreshData();
                            this.closeModal();
                            this.$router.push({name:'Home'})
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$emit('error-modal',error[0]);
                }
            });
        },
        closeModal() {
            
            this.$refs.close_btn.click();
        }
    }
}

</script>