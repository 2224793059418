<template>
    <div class="mobile-bets-popup">
        <div class="modal fade" id="exampleModalTogglebets" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
            tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalToggleLabel">Open Bets</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="openBetsTabs">
                            <div class="tab-container">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="active nav-item" role="presentation">
                                        <a href="#" class="nav-link active" id="Matched-tab" data-bs-toggle="tab"
                                            data-bs-target="#Matched" type="button" role="tab" aria-controls="Matched"
                                            aria-selected="true">
                                            <span>Matched</span>
                                        </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a href="#" class="nav-link" id="UnMatched-tab" data-bs-toggle="tab"
                                            data-bs-target="#UnMatched" type="button" role="tab" aria-controls="UnMatched"
                                            aria-selected="false">
                                            <span>UnMatched</span>
                                        </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a href="#" class="nav-link" id="Fancy-tab" data-bs-toggle="tab"
                                            data-bs-target="#Fancy" type="button" role="tab" aria-controls="Fancy"
                                            aria-selected="false">
                                            <span>Fancy</span>
                                        </a>
                                    </li>
                                    <!---->
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="Matched" role="tabpanel"
                                        aria-labelledby="Matched-tab">
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <td>Selname</td>
                                                        <td>Odds</td>
                                                        <td>Stake</td>
                                                        <td>Date/Time</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Mark</td>
                                                        <td>Mark</td>
                                                        <td>Mark</td>
                                                        <td>Mark</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="UnMatched" role="tabpanel"
                                        aria-labelledby="UnMatched-tab">
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <td>Selname</td>
                                                        <td>Odds</td>
                                                        <td>Stake</td>
                                                        <td>Date/Time</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Mark</td>
                                                        <td>Mark</td>
                                                        <td>Mark</td>
                                                        <td>Mark</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="Fancy" role="tabpanel" aria-labelledby="Fancy-tab">
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <td>Selname</td>
                                                        <td>Odds</td>
                                                        <td>Stake</td>
                                                        <td>Date/Time</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Mark</td>
                                                        <td>Mark</td>
                                                        <td>Mark</td>
                                                        <td>Mark</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <a class="btn btn-primary">Open first modal</a> -->
    </div>
</template>
<script>
export default {
    name: 'MobileBetsMenu'
}
</script>