const AccountModule = () => import(/* webpackChunkName: "account-module" */ './views/Module.vue'); 
const MobileAccount = () => import(/* webpackChunkName: "mobile-account" */ './views/MobileAccount.vue');
const RulesPage = () => import(/* webpackChunkName: "mobile-account" */ './views/Rules.vue');

const AccountRoutes =   {
    path: '/',
    component: AccountModule,
    children: [
      {
        path: 'mobile-account',
        name: 'mobile-account',
        component: MobileAccount
      },
      {
        path: 'rules',
        name: 'rules',
        component: RulesPage
      }
    ],
  }

export default AccountRoutes;