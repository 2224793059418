const DashboardModule = () => import(/* webpackChunkName: "dashboard-module" */ './views/Module.vue'); 
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue');

const DashboardRoutes =   {
    path: '/',
    component: DashboardModule,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard
      }
    ],
  }

export default DashboardRoutes;