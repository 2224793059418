<template>
    <section class="banner-wapper" >
        <div class="">
            <div class="mobile-view-nav">
                <div class="">
                    <div class="">
                        <div class="mobile-view">
                            <ul class="home-navigation-bar ">
                                <li v-for="(sport, index) in filteredSports.commonSports" :key="index">
                                    <router-link :to="{ name: 'sports', params: { type: sport.slug, id: sport.id } }">
                                        {{ translatedLangData(sport.slug,sport.name)}}
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/int-casino">{{ translatedLangData('int-casino', 'Int Casino') }}</router-link>
                                </li>
                                <li>
                                    <router-link to="/cricket-fight">{{ translatedLangData('cricket-fight', 'Cricket Fight') }}</router-link>
                                </li>
                                <li>
                                    <router-link to="/matka">{{ translatedLangData('matka','Matka')}}</router-link>
                                </li>
                                <li v-for="(sport, index) in filteredSports.extraSports" :key="index">
                                    <router-link :to="{ name: 'sports', params: { type: sport.slug, id: sport.id } }">
                                        {{ translatedLangData(sport.slug,sport.name)}}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="las-head-topic-sec">
                <ul>
                    <li v-for="(game, index) in custom_sports" :key="index">
                        <router-link :to="'/sports/' + game.name + '/' + game.id">{{ translatedLangData(game.slug,game.name)}}</router-link>
                    </li>
                </ul>
            </div>
            <div class="banners-img-adv">
                <div class="">
                    <div class="">
                        <a href="#">
                            <img  rel="preload"  :src="siteSettings?.aws_url + siteSettings?.storage_path.poster_path + sitePosters[0]?.image" :alt="sitePosters[0]?.image">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default{
    name:'Banner',
    inject:['translatedLangData'],
    data(){
        return{
            custom_sports:  this.$store.getters.sports_list?.custom ?? [],
            non_custom_sports: this.$store.getters.sports_list?.non_custom ?? [],
            breakpoints: {
                0: {
                    itemsToShow: 1,
                    snapAlign: 'start',
                },
                375: {
                    itemsToShow: 1.25,
                    snapAlign: 'start',
                },
                480: {
                    itemsToShow: 2,
                    snapAlign: 'start',
                },
                668: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                }
            },
            siteSettings: this.$store.getters.siteSettings
        }
    },
    computed: { 
        sitePosters() {
            if(this.siteSettings && this.siteSettings.poster && this.siteSettings.poster.length) {
                return this.siteSettings.poster.filter(s => s.poster_type == 1);
            } else {
                return [];
            }
        },
        filteredSports() {
            return {
            commonSports: this.non_custom_sports.filter(sport => [1, 2, 4].includes(sport.id)),
            extraSports: this.non_custom_sports.filter(sport => ![1, 2, 4].includes(sport.id))
            };
        }
    }
}
</script>

<style scoped>
.home-navigation-bar{
    justify-content: unset;
}
</style>