import * as apiName from '../services/urls';
import api from '../services/api'
import { AUTH_TOKEN } from "@/shared/constants/cookies-const";


export function getWalletGetwayServiceCall() {

    let headers = {
        'Authorization': `Bearer ${window.cookies.get(AUTH_TOKEN)}`
    }
    api.get(apiName.GET_WALLET_GETWAY, {headers}).then(response => {
        if (response && response.status == 200) {
            if (response?.data?.status === 0) {
            } else {
                try {
                    let resData = response?.data?.data;
                    window.open(resData, '_blank')
                } catch (error) {
                    console.log("Error setCaptchaData : ", error);
                }

            }
        }
    }).catch(error => {
        if (error) {
            console.log(error)
        }
    });
}